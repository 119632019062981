<template>
  <div class="content">
    <div class="main-content" ref="content">
      <a-spin tip="Loading..." :spinning="loading">
        <a-row>
          <a-col :span="20">
            <a-form>
              <e-input
                title="套餐名称"
                :validate="validateInfos.name"
                v-model:value="modelRef.name"
              />

              <a-row style="padding-left: 2px">
                <a-col :span="7" :offset="1">
                  <e-input-number
                    title="餐标"
                    :validate="validateInfos.unitPrice"
                    v-model:value="modelRef.unitPrice"
                  />
                </a-col>
                <a-col :span="7">
                  <e-input-number
                    title="人数"
                    :validate="validateInfos.peopleCount"
                    v-model:value="modelRef.peopleCount"
                  />
                </a-col>

                <a-col :span="8">
                  <e-info
                    title="套餐价格"
                    :labelCol="12"
                    :valueCol="12"
                    :value="'¥' + modelRef.peopleCount * modelRef.unitPrice"
                  />
                </a-col>
              </a-row>

              <a-row style="padding-left: 2px">
                <a-col :span="7" :offset="1">
                  <e-info
                    title="成本价"
                    :labelCol="10"
                    :valueCol="12"
                    :value="'¥' + modelRef.price"
                  />
                </a-col>
                <a-col :span="7">
                  <e-info
                    title="菜品售价"
                    :labelCol="10"
                    :valueCol="12"
                    :value="'¥' + modelRef.sellingPrice"
                  />
                </a-col>
                <a-col :span="7">
                  <e-info
                    title="毛利率"
                    :labelCol="10"
                    :valueCol="12"
                    :value="maolilv"
                  />
                </a-col>
              </a-row>

              <a-row>
                <a-col :span="4">
                  <div class="label-title" style="font-weight: 800">菜品</div>
                </a-col>
                <a-col :span="20">
                  <div class="fields">
                    <a-table
                      :columns="columns"
                      :data-source="modelRef.dishItems"
                      :pagination="false"
                      :loading="loading2"
                      :row-key="(index) => index"
                      size="small"
                      :scroll="{ y: height, x: true }"
                    >
                      <template #bodyCell="{ column, record, index }">
                        <template v-if="column.key === 'index'">
                          {{ index + 1 }}
                        </template>

                        <template v-if="column.key === 'dishName'">
                          {{ record.dishName }}
                          ({{ record.dishUnit }})
                        </template>

                        <template v-if="column.key === 'type'">
                          {{ record.isPersonal ? "人均" : "份" }}
                        </template>

                        <template v-if="column.key === 'dishPrice'">
                          ¥{{ record.dishPrice }}
                        </template>

                        <template v-if="column.key === 'dishSellingPrice'">
                          ¥{{ record.dishSellingPrice }}
                        </template>

                        <template v-if="column.key === 'count'">
                          <a-button
                            type="link"
                            :disabled="record.count <= 1"
                            size="small"
                            @click.stop="minus(index)"
                          >
                            <minus-outlined />
                          </a-button>
                          <a-input-number
                            size="small"
                            v-model:value="record.count"
                            :min="1"
                          />
                          <a-button
                            type="link"
                            size="small"
                            @click.stop="add(index)"
                          >
                            <plus-outlined />
                          </a-button>
                        </template>

                        <template v-if="column.key === 'action'">
                          <a-tooltip>
                            <template #title>删除</template>
                            <a @click.stop="delFields(index)">
                              <DeleteOutlined />
                            </a>
                          </a-tooltip>
                        </template>
                      </template>
                    </a-table>
                    <div
                      style="
                        padding: 5px;
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      <div>
                        <a-button
                          type="primary"
                          @click="$refs.dishes.open()"
                          size="small"
                        >
                          添加菜品
                        </a-button>
                      </div>
                      <a-button
                        type="primary"
                        danger
                        @click="clearDish"
                        size="small"
                      >
                        清空
                      </a-button>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </a-form>
          </a-col>
        </a-row>
      </a-spin>

      <div class="form-footer">
        <a-button
          @click="onSubmitFun"
          :loading="loading"
          size="large"
          type="primary"
        >
          提交
        </a-button>
        <a-button
          v-if="!id"
          @click="resetFields"
          size="large"
          style="margin-left: 15px"
        >
          重置
        </a-button>
        <a-button
          v-else
          @click="fetchData"
          size="large"
          style="margin-left: 15px"
        >
          重置
        </a-button>
      </div>

      <e-modal
        :visible="visible"
        :title="title"
        :width="600"
        @close="onClose"
        @ok="onAddFieldFun"
      >
        <a-form>
          <SearchDish v-model:value="modelRef2.dishId" @ok="chooseDish" />

          <a-row style="padding-left: 2px">
            <a-col :span="7" :offset="1">
              <e-input-number
                title="数量"
                v-model:value="modelRef2.count"
                :min="1"
                :step="1"
              />
            </a-col>
          </a-row>
        </a-form>
      </e-modal>

      <Dishes ref="dishes" @ok="onPushDish" />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRaw, createVNode } from 'vue'
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  MinusOutlined,
  PlusOutlined
} from '@ant-design/icons-vue'
import { ComboClass } from '@/apis/combo'
import { DishClass } from '@/apis/dish'
import { Form } from 'ant-design-vue'

import form from '@/mixins/form'
import SearchDish from '@/components/form/SearchDish'
import Dishes from '@/components/forms/combo/Dishes'

const useForm = Form.useForm
const api = new ComboClass()
const dish = new DishClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  components: {
    DeleteOutlined,
    SearchDish,
    MinusOutlined,
    PlusOutlined,
    Dishes
  },
  setup () {
    const loading = ref(false)
    const loading2 = ref(false)
    const id = ref('')
    const index = ref(-1)
    const fields = ref([])
    const height = ref(0)

    const modelRef2 = ref({
      dishId: '',
      dishName: '',
      dishUnit: '',
      isPersonal: false,
      dishPrice: 0,
      dishSellingPrice: 0,
      count: 1
    })

    const visible = ref(false)
    const title = ref('')
    const onClose = () => {
      modelRef2.value = {
        dishId: '',
        dishName: '',
        dishUnit: '',
        isPersonal: false,
        dishPrice: 0,
        dishSellingPrice: 0,
        count: 1
      }
      index.value = -1
      visible.value = false
    }

    const modelRef = reactive(api.modelRef)
    const rulesRef = reactive(api.rulesRef)
    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const chooseDish = (e) => {
      modelRef2.value.dishName = e.name
      modelRef2.value.dishUnit = e.unit
      modelRef2.value.isPersonal = e.isPersonal
      modelRef2.value.dishPrice = e.price
      modelRef2.value.dishSellingPrice = e.sellingPrice
    }

    const onPushDish = (e) => {
      loading2.value = true
      dish.getList(e).then((resp) => {
        resp.map((e) => {
          modelRef.dishItems.push({
            dishName: e.name,
            dishUnit: e.unit,
            isPersonal: e.isPersonal,
            dishPrice: e.price,
            dishSellingPrice: e.sellingPrice,
            dishId: e.id,
            count: 1
          })
        })
        loading2.value = false
      })
    }

    const clearDish = () => {
      modelRef.dishItems = []
    }

    return {
      loading,
      loading2,
      id,
      fields,
      visible,
      title,
      onClose,
      chooseDish,
      onPushDish,
      clearDish,
      index,

      modelRef,
      validateInfos,
      resetFields,
      validate,
      modelRef2,
      height,
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 50
        },
        {
          title: '菜品',
          key: 'dishName',
          dataIndex: 'dishName'
        },
        {
          title: '类型',
          key: 'type',
          dataIndex: 'type',
          width: 60
        },
        {
          title: '成本价',
          key: 'dishPrice',
          dataIndex: 'dishPrice',
          width: 100
        },
        {
          title: '售价',
          key: 'dishSellingPrice',
          dataIndex: 'dishSellingPrice',
          width: 100
        },
        {
          title: '数量',
          key: 'count',
          dataIndex: 'count',
          width: 170
        },
        {
          title: '删除',
          key: 'action',
          dataIndex: 'action',
          width: 45
        }
      ]
    }
  },

  created () {
    this.id = this.$route.params.id

    this.$watch('stockPrice', (e) => {
      this.modelRef.price = e.price
      this.modelRef.sellingPrice = e.sellingPrice
    })

    this.fetchData()

    this.$nextTick(() => {
      this.getTableHeight()
    })
  },

  unmounted () {
    this.resetFields()
  },

  computed: {
    stockPrice () {
      const items = this.modelRef.dishItems
      const num = this.modelRef.peopleCount

      if (items.length === 0) {
        return {
          price: 0,
          sellingPrice: 0
        }
      }
      let res = 0
      let res2 = 0
      items.map((e) => {
        if (e.isPersonal) {
          // 人均配菜
          res += e.dishPrice * e.count * num
          res2 += e.dishSellingPrice * e.count * num
        } else {
          res += e.dishPrice * e.count
          res2 += e.dishSellingPrice * e.count
        }
      })

      res = res.toFixed(2)
      return {
        price: Number(res),
        sellingPrice: Number(res2)
      }
    },

    maolilv () {
      if (!this.modelRef.peopleCount || !this.modelRef.unitPrice) {
        return '0%'
      }
      const shoujia = this.modelRef.peopleCount * this.modelRef.unitPrice
      const chengben = this.modelRef.price
      return (((shoujia - chengben) / shoujia) * 100).toFixed(2) + '%'
    }
  },

  methods: {
    fetchData () {
      if (this.id) {
        this.loading2 = true
        api.detail(this.id).then((resp) => {
          this.modelRef.id = resp.id
          this.modelRef.name = resp.name
          this.modelRef.unitPrice = resp.unitPrice
          this.modelRef.peopleCount = resp.peopleCount
          this.modelRef.price = resp.price
          this.modelRef.sellingPrice = resp.sellingPrice
          this.modelRef.dishItems = resp.comboDishes

          this.loading2 = false
        })
      }
    },

    onSubmitFun () {
      this.loading = true

      this.validate()
        .then(() => {
          const data = toRaw(this.modelRef)

          if (this.id) {
            this.updateFun(data)
          } else {
            this.createFun(data)
          }
        })
        .catch((e) => {
          this.loading = false
          e.errorFields.map((e) => {
            e.errors.map((x) => {
              this.$message.error(x)
            })
          })
        })
    },

    updateFun (data) {
      api
        .update(data)
        .then(() => {
          this.loading = false
          this.$message.success('编辑成功')
          this.$router.back()
        })
        .catch((err) => {
          this.loading = false
          this.$message.error('编辑失败')
          console.log('error', err)
        })
    },

    createFun (data) {
      delete data.id
      const _this = this
      api
        .create(data)
        .then(() => {
          this.loading = false
          _this.$confirm({
            title: '添加成功！',
            icon: createVNode(ExclamationCircleOutlined),
            content: createVNode(
              'div',
              {
                style: 'color:red;'
              },
              '是否继续添加？'
            ),
            onOk () {
              _this.resetFields()
            },
            onCancel () {
              _this.$router.back()
            }
          })
        })
        .catch((err) => {
          this.loading = false
          this.$message.error('添加失败')
          console.log('error', err)
        })
    },

    addFields () {
      this.title = '添加菜品'
      this.visible = true
    },
    onAddFieldFun () {
      const data = toRaw(this.modelRef2)

      if (!data.dishId || data.count <= 0) return false
      if (this.index === -1) {
        this.modelRef.dishItems.push(data)
      }

      this.onClose()
    },
    editFields (index) {
      this.title = '编辑菜品'
      this.index = index
      this.modelRef2 = this.modelRef.dishItems[index]
      this.visible = true
    },
    delFields (index) {
      this.modelRef.dishItems.splice(index, 1)
    },
    minus (index) {
      this.modelRef.dishItems[index].count--
    },
    add (index) {
      this.modelRef.dishItems[index].count++
    },
    getTableHeight () {
      this.height =
        this.$refs.content.offsetHeight - 112 - 30 - 32 - 80 - 30 - 50
      console.log(this.$refs.content.offsetHeight)
    }
  }
})
</script>

<style lang="less" scoped>
.form-footer {
  position: absolute;
  bottom: 9px;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.1);
  z-index: 10003;
  border-radius: 0 0 15px 15px;
  padding: 0 20px;
}

.fields {
  border: 1px solid #ccc;
}
</style>
